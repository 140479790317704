// for header
document.addEventListener("DOMContentLoaded", function(event) {
  let searchFormOpenBtn = document.querySelector('.search-form__open-btn'),
      searchForm = document.querySelector('.search-form'),
      headerLogo = document.querySelector('.header .logo'),
      burger = document.querySelector('.burger'),
      nav = document.querySelector('.nav');

  searchFormOpenBtn.addEventListener('click', function(e) {
    e.preventDefault();
    searchForm.classList.toggle("search-form--open");
    headerLogo.classList.toggle("logo--hide");
  });

  burger.addEventListener('click', function(e) {
    e.preventDefault();
    nav.classList.toggle("nav--open");
  });
});
