// for sidebar
document.addEventListener("DOMContentLoaded", function(event) {
  let sidebarListBtn = document.querySelector('.sidebar-list-btn'),
      sidebarList = document.querySelector('.sidebar-list');

  sidebarListBtn.addEventListener('click', function(e) {
    e.preventDefault();
    sidebarListBtn.classList.toggle("sidebar-list-btn--open");
    sidebarList.classList.toggle("sidebar-list--open");
  });
});
